<template>
  <div class="vg_wrapper">
    <el-card>
      <el-row>
        <el-col :md="24">
          <el-button-group>
            <el-button size="small" @click="switchTabs(1)">未出运/部分出运</el-button>
            <el-button size="small" @click="switchTabs(2)">已出运</el-button>
            <el-button size="small" type="warning" @click="generateDialogVisible = !generateDialogVisible" :disabled="clickFlag">生成出运信息</el-button>
            <el-button size="small" type="primary" @click="instShipList">加入待选列表</el-button>
          </el-button-group>
          <el-date-picker
            v-model="dateRange"
            class="vg_ml_8 vg_mr_8 topDateRangeSearch"
            end-placeholder="装运结束月份"
            range-separator="至"
            size="small"
            start-placeholder="装运开始月份"
            type="monthrange"
            value-format="timestamp"
          />
          <el-button size="small" type="primary" @click="getDequsNow">查询</el-button>
          <el-button icon="el-icon-refresh-right" size="small" type="info" @click="buttonRefresh()">刷新</el-button>
          <div style="float: right">
            <div style="display: flex">
              <div style="width: 250px">所有页总体积合计: {{ this.sumTotal }}</div>
              <span>当页总体积合计: {{ this.sumProd_tvol }}</span>
            </div>
          </div>
          <search-table
            ref="multiTable"
            :data="tableData"
            :stripe="false"
            :columns="tableProperties"
            :tableRowKey="`podr_part_id`"
            @select="handleSelectionChange2"
            @selection-change="handleSelectionChange"
            @select-all="handleSelectionChange1"
            v-loading="loadFlag"
            :totalPage="totalPage"
            @getTableData="getDequsNow"
            :pageSize="50"
            :row-style="setRowStyle"
          >
          </search-table>
        </el-col>
        <div class="detail" style="opacity: 0.5">
          <el-button type="primary" icon="el-icon-s-fold" circle @click="waitShipDialogVisible = true" />
        </div>
        <el-dialog title="生成出运信息" :visible.sync="waitShipDialogVisible" width="60%">
          <div v-loading="waitTranOkLoading">
            <div style="margin: 0 0 10px 0">
              <el-button type="danger" size="small" @click="delShipList">移除</el-button>
              <el-button type="primary" size="small" @click="waitShipDialogVisible = false">继续筛选</el-button>
            </div>
            <el-table :data="waitShipTableData" border max-height="630" @selection-change="selectionChangeShip">
              <el-table-column type="selection" width="48" align="center" />
              <el-table-column label="序号" :show-overflow-tooltip="true" align="center" width="50">
                <template v-slot="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>
              <el-table-column label="销售合同号" prop="scon_no" :show-overflow-tooltip="true" />
              <el-table-column label="公司抬头" prop="cptt_aname_en" :show-overflow-tooltip="true" />
              <el-table-column label="客户订单号" prop="scon_cust_no" :show-overflow-tooltip="true" />
              <el-table-column label="客户货号" prop="prod_cust_no" :show-overflow-tooltip="true" />
              <el-table-column
                label="装运日期"
                prop="scon_shdate"
                :show-overflow-tooltip="true"
                :formatter="({ scon_shdate }) => getDateNoTime(scon_shdate, true)"
              />
              <el-table-column label="已出运数量" prop="prod_ship_already" :show-overflow-tooltip="true" />
              <el-table-column label="未出运数量" prop="prod_ship" :show-overflow-tooltip="true" />
            </el-table>
            <span style="display: flex; justify-content: center; align-items: center; margin: 10px">
              <el-button type="success" @click="waitTranOk">生成出运信息</el-button>
            </span>
          </div>
        </el-dialog>
        <el-dialog title="生成出运信息" :visible.sync="generateDialogVisible" width="60%">
          <div v-loading="tranOkLoading">
            <el-table :data="shipTableData" border max-height="630">
              <el-table-column label="序号" :show-overflow-tooltip="true" align="center" width="50">
                <template v-slot="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>
              <el-table-column label="销售合同号" prop="scon_no" :show-overflow-tooltip="true" />
              <el-table-column label="客户PO号" prop="scon_cust_no" :show-overflow-tooltip="true" />
              <el-table-column label="客户货号" prop="prod_cust_no" :show-overflow-tooltip="true" />
              <el-table-column label="货号后缀" prop="prod_poststfix" :show-overflow-tooltip="true" />
              <el-table-column label="操作" :show-overflow-tooltip="true" align="center">
                <template v-slot="scope">
                  <el-link class="vg_cursor" type="danger" @click="deleteTab1(scope)">删除</el-link>
                </template>
              </el-table-column>
            </el-table>
            <span style="display: flex; justify-content: center; align-items: center; margin: 10px">
              <el-button @click="generateDialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="TranOk">确 定</el-button>
            </span>
          </div>
        </el-dialog>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { spinAPI } from '@api/modules/spin';
import { stffAPI } from '@api/modules/staff';
import { optnAPI } from '@api/modules/optn';
import { custAPI } from '@api/modules/cust';
import { tranAPI } from '@api/modules/tran';
import pubPagination from '@/components/common/pubPagination';
import { myMixin } from '@/assets/js/mixin/tabMixin.js';
import { BigNumber } from 'bignumber.js';
import { getDateNoTime } from '@assets/js/dateUtils';
import { cloneDeep, debounce } from 'lodash';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { setTime } from '@api/public';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'DequList',
  components: {
    SearchTable,
    pubPagination
  },
  mixins: [myMixin],
  data() {
    return {
      generateDialogVisible: false,
      waitShipDialogVisible: false,
      activeName: '未出运',
      tranOkLoading: false,
      waitTranOkLoading: false,
      tableProperties: [
        { prop: 'selection', type: 'selection', labelWidth: '48px' },
        { prop: 'index', type: 'index', labelWidth: '50px', label: '序号' },
        { prop: 'cptt_no', label: '公司代码', itemType: 'input' },
        { prop: 'scon_no', label: '外销合同编号', itemType: 'input' },
        { prop: 'cptt_aname_en', label: '公司抬头', itemType: 'input' },
        { prop: 'scon_cust_no', label: '客户订单号', itemType: 'input' },
        { prop: 'cust_abbr', label: '客户简称', itemType: 'input' },
        { prop: 'scon_cndate', label: '合同日期', itemType: 'date', formatter: val => getDateNoTime(val, true), sortable: true },
        { prop: 'prod_cust_no', label: '客户货号', itemType: 'input' },
        { prop: 'prod_no', label: '我司货号', itemType: 'input' },
        { prop: 'prod_poststfix', label: '货号后缀', itemType: 'input', input: false },
        { prop: 'supp_abbr', label: '供应商简称', itemType: 'input' },
        { prop: 'scon_shdate', label: '装运日期', itemType: 'date', formatter: val => getDateNoTime(val, true) },
        { prop: 'cust_dport', label: '目的港', itemType: 'input' },
        { prop: 'prod_num', label: '订单数量', itemType: 'input', sortable: true, input: false },
        { prop: 'podr_part_num', label: '采购数量', itemType: 'input', sortable: true, input: false },
        { prop: 'prod_ship_already', label: '已出运数量', itemType: 'input', sortable: false, input: false },
        { prop: 'prod_ship', label: '未出运数量', itemType: 'input', sortable: false, input: false },
        {
          prop: 'prod_tvol',
          label: '总体积',
          itemType: 'input',
          sortable: false,
          input: false,
          needOtherColumn: true
        }
      ],
      searchForm: {},
      loadFlag: true,
      dequStffList: [],
      custList: [],
      currentPage: 1,
      totalPage: 0,
      tableData: [],
      waitShipTableData: [],
      shipTableData: [],
      multiSelection: [],
      btn: {},
      stffUserList: [],
      custDportList: [],
      deleteCopy: [],
      clickFlag: false,
      sortNum: 0,
      sortFlag: '',
      sumProd_tvol: 0,
      sumTotal: '',
      multiSelectionShip: [],
      dateRange: []
    };
  },
  created() {},
  mounted() {
    this.initData();
  },
  // watch: {
  //   $route(to, from) {
  //     // if (from.path === '/dequ_add' || from.path === '/dequ_edit') {
  //     //   this.initData();
  //     // }
  //     // if (to.path === '/spin_list') {
  //     //   this.buttonRefresh();
  //     // }
  //   }
  // },
  methods: {
    getDateNoTime,
    initData() {
      this.switchTabs(1);
      this.getDequStffList();
      this.getCustList();
      this.getStffUser();
      this.getCustDport();
    },
    /**
     * 切换选项卡
     */
    switchTabs(index) {
      this.loadFlag = true;
      this.multiSelection = [];
      this.$refs.multiTable.searchForm.prod_shipstat = index;
      this.getDequs();
    },
    // 获取数据
    getDequs() {
      let searchForm = this.$refs.multiTable.searchForm;
      setTime(searchForm, 'startTime', 'endTime', this.dateRange || [], false);
      get(spinAPI.getSpins, searchForm).then(res => {
        if (res.data.code === 0) {
          this.tableData = res.data.data.list;
          let sumTvol = 0;
          this.tableData.forEach(item => {
            sumTvol += item.prod_tvol;
          });
          this.sumProd_tvol = this.helper.calcPrice(sumTvol); //计算总立方数
          this.totalPage = res.data.data.total;
          this.btn = res.data.data.btn;
          this.sumTotal = res.data.data.sumTotal;
          setTimeout(() => {
            this.loadFlag = false;
            this.changeTable();
          }, 500);
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message.error({ message: mg, type: tp });
        }
      });
    },
    //复制的数据变绿色
    setRowStyle({ row, rowIndex }) {
      row.index = rowIndex;
      if (this.waitShipTableData) {
        let find = this.waitShipTableData.find(x => x.podr_part_id === row.podr_part_id);
        if (find) {
          return { background: 'rgb(199,230,165)' };
        } else {
          return { background: '#fff' };
        }
      }
    },
    changeTable() {
      let temp = [];
      let temp1 = [];
      for (let i = 0; i < this.tableData.length; i++) {
        for (let j = 0; j < this.deleteCopy.length; j++) {
          if (this.tableData[i].scon_prod_id === this.deleteCopy[j].scon_prod_id) {
            temp.push(i);
            temp1.push(j);
          }
        }
      }
      temp.forEach(row => {
        this.$refs.multiTable.$refs.multiTable.toggleRowSelection(this.tableData[row]);
      });
      temp1 = temp1.sort((a, b) => {
        return b - a;
      });
      for (let i of temp1) {
        this.deleteCopy.splice(i, 1);
      }
    },
    // 计算总体积
    formatProdVol(val) {
      let totalA = new BigNumber(1);
      totalA = totalA.times(val.prod_vol ? val.prod_vol : 0).times(val.prod_ship ? val.prod_ship : 0);
      totalA = totalA.toNumber();
      return this.helper.haveFour(totalA + '');
    },
    // 简称更改
    custChange(val) {
      if (val) {
        this.getDequs();
      }
    },
    // 获取港
    getCustDport() {
      get(optnAPI.getOptnByPermId, { perm_id: 10018 }).then(res => {
        if (res.data.code === 0) {
          this.custDportList = res.data.data.form.optn_cntt_list;
          return;
        }
        this.$message.error(res.data.msg);
      });
    },
    // 获取委托人
    getDequStffList() {
      get(stffAPI.getAllStffsV1).then(res => {
        if (res.data.code === 0) {
          this.dequStffList = res.data.data;
        }
      });
    },
    // 获取客户
    getCustList() {
      get(custAPI.getAllCustsV1).then(res => {
        if (res.data.code === 0) {
          this.custList = res.data.data.list;
        }
      });
    },
    // 指定一个key标识这一行的数据
    getRowKey(row) {
      return row.scon_prod_id;
    },
    // 查询方法
    getDequsNow: debounce(function () {
      this.loadFlag = true;
      this.currentPage = 1;
      this.getDequs();
    }, 1000),
    // 刷新
    buttonRefresh() {
      this.activeName = null;
      this.loadFlag = true;
      this.currentPage = 1;
      this.sortNum = 0;
      this.sortFlag = '';
      this.$refs.multiTable.searchForm = { page_no: 1 };
      this.$refs.multiTable.$refs.multiTable.clearSelection();
      this.$refs.multiTable.$refs.pubPagination.currentPage = 1;
      this.dateRange = [];
      this.initData();
    },
    // 分页查询
    changePageSearch(val) {
      this.loadFlag = true;
      this.currentPage = val;
      this.getDequs();
    },
    handleClick(e) {
      // 阻断冒泡上级元素事件
      e.cancelBubble = true;
    },
    //升序 降序
    sortChange({ order, prop }) {
      if (!order) {
        this.searchForm.column = '';
        this.searchForm.order = '';
        return;
      }
      this.searchForm.column = prop;
      this.searchForm.order = order.substring(0, order.indexOf('ending'));
    },
    // 多选获取出运
    handleSelectionChange(val) {
      this.multiSelection = val;
      this.shipTableData = JSON.parse(JSON.stringify(val));
    },
    // 出运多选
    selectionChangeShip(val) {
      this.multiSelectionShip = [];
      this.multiSelectionShip = val;
    },
    //待选出运多选删除出运
    delShipList() {
      for (let i = 0; i < this.multiSelectionShip.length; i++) {
        this.waitShipTableData.forEach(item => {
          if (item.podr_part_id === this.multiSelectionShip[i].podr_part_id) {
            let currentIndex = this.waitShipTableData.indexOf(item);
            this.waitShipTableData.splice(currentIndex, 1);
          }
        });
      }
    },
    //多选加入待选出运列表
    instShipList() {
      if (this.multiSelection.length <= 0) {
        return this.$message.warning('至少选择一条数据');
      }
      let tabdata = cloneDeep(this.waitShipTableData);
      //添加时过滤掉重复的数据
      this.multiSelection.forEach(item => {
        let find = tabdata.find(x => x.podr_part_id === item.podr_part_id);
        if (!find) this.waitShipTableData.push(item);
      });
      let notCust_abbr = new Set(Array.from(this.waitShipTableData, ({ cust_abbr }) => cust_abbr));
      let cust_dport = new Set(Array.from(this.waitShipTableData, ({ cust_dport }) => cust_dport));
      if (notCust_abbr.size > 1 || cust_dport.size > 1) {
        this.waitShipTableData = tabdata;
        return this.$message.warning('相同客户简称，相同港口，才可一起出运！');
      }
      this.$refs.multiTable.clearSelection();
      return this.$message.success('已加入待选列表');
    },
    // 全选
    handleSelectionChange1(val) {
      // let temp = [];
      // val.forEach((item, index) => {
      //   for (let i = 0; i < this.tableData.length; i++) {
      //     if (this.tableData[i].scon_prod_id === item.scon_prod_id) {
      //       if (item.prod_shipstat === 1) {
      //         temp.push(i);
      //       }
      //     }
      //   }
      // });
      // temp.forEach(item => {
      //   this.$nextTick(() => {
      // this.$refs.multiTable.$refs.multiTable.toggleRowSelection(val);
      // });
      // });
    },
    // 单选
    handleSelectionChange2(val) {
      // if (val[0].prod_shipstat === 1) {
      //   for (let i = 0; i < this.tableData.length; i++) {
      //     if (this.tableData[i].scon_prod_id === val[0].scon_prod_id) {
      //       this.$nextTick(() => {
      //         this.$refs.multiTable.$refs.multiTable.toggleRowSelection(this.tableData[i]);
      //       });
      //     }
      //   }
      // }
    },
    // 生成表删除
    deleteTab1(scope) {
      let temp = -1;
      for (let i in this.tableData) {
        if (scope.row.podr_part_id === this.tableData[i].podr_part_id) {
          temp = i;
        }
      }
      if (temp !== -1) {
        this.$nextTick(() => {
          this.$refs.multiTable.$refs.multiTable.toggleRowSelection(this.tableData[temp]);
          // this.waitShipTableData.splice(scope.$index,1)
        });
      } else {
        this.deleteCopy.push(JSON.parse(JSON.stringify(scope.row)));
        // this.waitShipTableData.splice(scope.$index, 1);
      }
    },
    //获取录取人信息
    getStffUser() {
      get(stffAPI.getAllStffsV1).then(res => {
        if (res.data.code === 0) {
          this.stffUserList = res.data.data;
          return;
        }
        this.$message.error(res.data.msg);
      });
    },
    //直接生成出运信息
    TranOk() {
      this.tranOkLoading = true;
      if (this.shipTableData.length === 0) {
        this.tranOkLoading = false;
        return this.$message.warning('至少选择一条出运');
      }
      let notCust_abbr = new Set(Array.from(this.shipTableData, ({ cust_abbr }) => cust_abbr));
      let cust_dport = new Set(Array.from(this.shipTableData, ({ cust_dport }) => cust_dport));
      if (notCust_abbr.size > 1 || cust_dport.size > 1) {
        this.tranOkLoading = false;
        return this.$message.warning('相同客户简称，相同港口，才可一起出运！');
      }
      //已出运的单据无法重复出运
      let length = this.shipTableData.filter(x => Number(x.prod_ship) <= 0).length;
      if (length > 0) {
        this.tranOkLoading = false;
        return this.$message.warning('已出运的单据无法重复出运！');
      }
      let temp = [];
      this.shipTableData.forEach(item => {
        temp.push(item.podr_part_id);
      });
      const podr_part_id = temp.join(',');
      this.clickFlag = true;
      if (this.clickFlag) {
        post(tranAPI.addTran, { podr_part_id: podr_part_id }).then(res => {
          let mg = res.data.msg;
          if (res.data.code === 0) {
            setTimeout(() => {
              this.clickFlag = false;
            }, 1000);
            this.generateDialogVisible = false;
            let tp = 'success';
            this.$message({ message: mg, type: tp });
            const permId = 130;
            this.tranOkLoading = false;
            this.jump('/tran_edit', {
              key: UrlEncode.encode(
                JSON.stringify({
                  perm_id: permId,
                  form_id: res.data.data.form_id
                })
              )
            });
          } else if (res.data.code === 999) {
            this.$message.error(res.data.msg);
          } else {
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        });
      }
    },
    //从待选列表生成出运管理
    waitTranOk() {
      this.waitTranOkLoading = true;
      if (this.waitShipTableData.length === 0) {
        this.waitTranOkLoading = false;
        return this.$message.warning('至少选择一条出运');
      }
      //已出运的单据无法重复出运
      let length = this.waitShipTableData.filter(x => Number(x.prod_ship) <= 0).length;
      if (length > 0) {
        this.waitTranOkLoading = false;
        return this.$message.warning('已出运的单据无法重复出运！');
      }
      let temp = [];
      this.waitShipTableData.forEach(item => {
        temp.push(item.podr_part_id);
      });
      const podr_part_id = temp.join(',');
      this.clickFlag = true;
      if (this.clickFlag) {
        post(tranAPI.addTran, { podr_part_id: podr_part_id }).then(res => {
          let mg = res.data.msg;
          if (res.data.code === 0) {
            setTimeout(() => {
              this.clickFlag = false;
            }, 1000);
            this.waitShipDialogVisible = false;
            //重置已选的出运
            this.waitShipTableData = [];
            this.waitTranOkLoading = false;
            this.$message({ message: mg, type: 'success' });
            const permId = 130;
            this.jump('/tran_edit', {
              key: UrlEncode.encode(
                JSON.stringify({
                  perm_id: permId,
                  form_id: res.data.data.form_id
                })
              )
            });
          } else if (res.data.code === 999) {
            this.$message.error(res.data.msg);
          } else {
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_posi_o {
  position: relative;
}

::v-deep .vd_posi_t {
  position: absolute;
  top: 10px;
  left: 30px;
  font-size: 24px;
  color: red;
}

::v-deep .fixed-cell {
  background-color: #ccc !important;
}
.vd_dirc {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.vd_sortColor {
  color: rgb(0, 196, 250);
}
::v-deep .el-dialog__footer {
  padding: 10px 20px 20px;
  text-align: center;
  box-sizing: border-box;
}
.detail {
  position: absolute;
  bottom: 100px;
  right: 50px;
}
</style>
